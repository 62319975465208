import { render, staticRenderFns } from "./HomeworkProgress.vue?vue&type=template&id=13394736&scoped=true&"
import script from "./HomeworkProgress.vue?vue&type=script&lang=js&"
export * from "./HomeworkProgress.vue?vue&type=script&lang=js&"
import style0 from "./HomeworkProgress.vue?vue&type=style&index=0&id=13394736&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13394736",
  null
  
)

export default component.exports